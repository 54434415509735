import React, { useState } from "react"
import { Carousel } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const ProductCarouselHeritageBrown = ({ children }) => {
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  return (
    <Carousel
      indicators={false}
      activeIndex={index}
      onSelect={handleSelect}
      data-bs-animation="false"
    >
      <Carousel.Item>
        <StaticImage
          src="../images/heritage_scarves/image_1_0.jpg"
          quality={100}
          formats={["AUTO", "WEBP"]}
          alt="heritage silk scarf in brown"
          className="img-fluid rounded"
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/heritage_scarves/image_1_1.jpg"
          quality={100}
          formats={["AUTO", "WEBP"]}
          alt="heritage silk scarf in brown on model around neck"
          className="img-fluid rounded"
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/heritage_scarves/image_1_2.jpg"
          quality={100}
          formats={["AUTO", "WEBP"]}
          alt="heritage silk scarf in brown close up"
          className="img-fluid rounded"
        />
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/heritage_scarves/image_1_3.jpg"
          quality={100}
          formats={["AUTO", "WEBP"]}
          alt="heritage silk scarf in brown on model as head scarf"
          className="img-fluid rounded"
        />
      </Carousel.Item>
    </Carousel>
  )
}

export default ProductCarouselHeritageBrown
